import { defineStore } from 'pinia';
import type { UserPayload } from '@/services';
import { useSocialStore } from '@/stores/social';
import { authService, transactionalService } from '@/services';
import { toast } from '@/utils/toast';
import { type Router } from 'vue-router';
import { SequenceWaaS } from '@0xsequence/waas';
import { handleAxiosError } from '@/utils/error';
import { queryClient } from '@/plugins/vue-query.ts';
import { useWalletStore } from './wallet';
import { type AxiosError } from 'axios';

interface AuthState {
  user: UserPayload | null;
  loginError: string;
  showLoginModal: boolean;
  redirectToHomeOnCloseLoginModal: boolean;
  canDoModalProps: CanDoModalProps;
  redirectToHomeOnCloseCanDoModal: boolean;
  googleClientId: string;
  isConnectingWallet: boolean;
  nonce: string;
  sequence: SequenceWaaS | null;
  walletAddress: WalletAddress;
  showAuthCodeModal: boolean;
  showLoginWalletModal: boolean;
  respondWithCode: (code: string) => void;
}

export interface RegistrationData {
  email: string;
  password: string;
  confirmPassword: string;
  username: string;
  tokenCode: string;
}

export interface CanDoModalProps {
  showCanDoModal: boolean;
  canDoTitle: string;
  canDoMessage: string;
}

export interface WalletAddress {
  sessionId: string;
  wallet: string;
  email?: string;
}

export const sso = {
  Facebook: 'facebook',
  Google: 'google',
  Microsoft: 'microsoft',
  UPM: 'upm',
} as const;

export type Sso = (typeof sso)[keyof typeof sso];

const updateSocialUserImage = async (picture: string) => {
  await transactionalService.updateSocialUserImage(picture);
  await useSocialStore().getMyUser();
  await queryClient.refetchQueries({
    queryKey: ['social-users'],
  });
};

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null as UserPayload | null,
    showLoginModal: false,
    redirectToHomeOnCloseLoginModal: false,
    redirectToHomeOnCloseCanDoModal: false,
    loginError: '',
    googleClientId: '',
    isConnectingWallet: false,
    canDoModalProps: {
      showCanDoModal: false,
      canDoTitle: '',
      canDoMessage: '',
    },
    sequence: null,
    nonce: '',
    walletAddress: {
      sessionId: '',
      wallet: '',
      email: '',
    },
    showAuthCodeModal: false,
    showLoginWalletModal: false,
    respondWithCode: () => undefined,
  }),
  actions: {
    async login(
      email: string,
      password: string,
      isSso?: Sso,
      ssoAuthCode?: string,
    ) {
      try {
        const resApi = await authService.login({
          email,
          password,
          isSso: isSso ?? '',
          ssoAuthCode: ssoAuthCode ?? '',
        });
        this.user = resApi.data;

        switch (resApi.status) {
          case 200:
            //load op in background, the user is now logged in
            void useSocialStore().getMyUser();
            if (isSso === 'google') {
              void this.sequenceLoginWithGoogle(this.user.token);
            }
            break;
          case 201:
            {
              if (isSso === 'google') {
                void this.sequenceLoginWithGoogle(this.user.token);
              }

              // new user, needs to be awaited
              const picture = (
                resApi.data as UserPayload & { picture?: string }
              ).picture;
              if (!!picture && picture !== '') {
                // async operations that don't need to be awaited
                void updateSocialUserImage(picture);
              } else {
                //load op in background, the user is now logged in
                void useSocialStore().getMyUser();
              }
            }
            break;
          default:
            throw new Error('Unexpected status code');
        }

        toast.success({
          title: 'Log in successful',
          message: `You are now logged in as ${this.user.username}`,
        });
      } catch (error) {
        handleAxiosError(error, true);
      }
    },

    sequenceInit(network: string, newNetwork = false) {
      if (!this.sequence || newNetwork) {
        this.sequence = new SequenceWaaS({
          projectAccessKey: import.meta.env.VITE_PROJECT_ACCESS_KEY,
          waasConfigKey: import.meta.env.VITE_WAAS_CONFIG_KEY,
          network,
        });
      }
    },

    async sequenceHash() {
      if (!this.sequence) return;
      const isSignedIn = await this.sequence.isSignedIn();
      if (isSignedIn) {
        this.walletAddress.sessionId = await this.sequence.getSessionId();
        this.walletAddress.wallet = await this.sequence.getAddress();
      }
      try {
        this.nonce = await this.sequence.getSessionHash();
      } catch (err) {
        console.error(err);
      }
    },

    async sequenceLoginWithGoogle(token: string) {
      const walletStore = useWalletStore();
      if (!this.sequence) return;

      try {
        const signedIn = await this.sequence.isSignedIn();
        if (signedIn) {
          const [sessionId, wallet] = await Promise.all([
            this.sequence.getSessionId(),
            this.sequence.getAddress(),
          ]);

          this.walletAddress.sessionId = sessionId;
          this.walletAddress.wallet = wallet;
          return;
        }

        this.walletAddress = await this.sequence.signIn(
          {
            idToken: token,
          },
          'legendaryumAliasSession',
        );
      } catch (error) {
        handleAxiosError(error);
      } finally {
        await walletStore.getIndexer();
      }
    },

    async sequenceLoginWithEmail() {
      if (!this.sequence || !this.user?.email) return;
      const walletStore = useWalletStore();

      try {
        this.sequence.onEmailAuthCodeRequired(async (respondWithCode) => {
          this.respondWithCode = (code: string) => {
            respondWithCode(code).catch((error: unknown) => {
              console.error('Error en respondWithCode:', error);
            });

            return undefined;
          };

          // Añadimos una promesa resuelta inmediatamente para cumplir con que la funcion sea async
          await Promise.resolve();
        });
        this.showAuthCodeModal = true;
        const data = await this.sequence.signIn(
          {
            email: this.user.email,
          },
          'sessionName',
        );
        this.walletAddress = data;
      } catch (error) {
        handleAxiosError(error);
      } finally {
        await walletStore.getIndexer(true);
      }
    },

    sendTokenLogin(answer: string) {
      if (!this.sequence || !this.user?.email) return;
      this.isConnectingWallet = true;

      try {
        this.respondWithCode(answer);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        this.isConnectingWallet = false;
        this.showAuthCodeModal = false;
      }
    },

    async validateCookie() {
      const socialStore = useSocialStore();
      try {
        const res = await authService.refreshCookie();
        this.user = res.data;

        await socialStore.getMyUser();
        //await this.validatePermissions();
        return true;
      } catch (error) {
        socialStore.isLoadingUser = false;
        return false;
      }
    },
    async logout() {
      try {
        if (this.user) {
          try {
            useWalletStore().resetWallet();
            await authService.logout();
            await this.sequence?.dropSession({
              sessionId: this.walletAddress.sessionId,
            });
            this.walletAddress.sessionId = '';
            this.walletAddress.wallet = '';
            this.walletAddress.email = '';
            this.sequence = null;

            useSocialStore().clearMyUser();
            await (this.router as Router).push({ path: '/' });
            toast.info({
              title: 'You are now logged out.',
              message: 'Log in again to unlock all of our content.',
            });
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
        //
      } finally {
        this.user = null;
      }
    },
    async refreshCookie() {
      try {
        const res = await authService.refreshCookie();
        this.user = res.data;
        return res.data;
      } catch (error) {
        if ((error as AxiosError).status === 409) {
          return 'conflict-another-device';
        }
        console.error(error);
      }
    },
    async getAuthUrl() {
      const { data } = await authService.getAuthUrl();
      this.googleClientId = data.google;
      return data;
    },
    async connectToRoom(room: string) {
      try {
        await authService.connectToRoom(room);
        return true;
      } catch (error) {
        if ((error as AxiosError).status === 400) {
          toast.error({
            title: 'Error',
            message: 'You are already connected to this room.',
          });
        }
        return false;
      }
    },
    async refreshConnection(room: string) {
      try {
        await authService.refreshConnection(room);
      } catch (error) {
        console.error((error as AxiosError).response);
      }
    },
  },
});
