import type { NavigationGuard } from 'vue-router';
import { useAuthStore, useSocialStore } from '@/stores';
import { setRouteToGo } from '@/utils/popup';
import { toast } from '@/utils/toast';
import { showcaseService } from '@/services';
import { handleAxiosError } from '@/utils/error';
import { useShowcaseStore } from '@/stores';
import { blockchainService } from '@/services/blockchain.service';

export const guards: Record<string, NavigationGuard> = {
  checkIfLoggedIn: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (authStore.$state.user?.id) {
      next();
      return;
    }

    await authStore.validateCookie();
    next();
  },
  mustBeLoggedIn: async (to, from, next) => {
    const authStore = useAuthStore();
    authStore.showLoginModal = false;
    authStore.redirectToHomeOnCloseLoginModal = false;

    if (authStore.$state.user?.id) {
      next();
      return;
    }

    const userCached = await authStore.validateCookie();
    if (userCached) {
      next();
      return;
    }

    setRouteToGo(to.fullPath);

    authStore.showLoginModal = true;

    authStore.redirectToHomeOnCloseLoginModal =
      (to.name == 'RoomView' || to.name == 'ExperienceView') &&
      from.matched.length === 0;

    return;
  },
  isRoomOwner: async (to, _, next) => {
    const socialStore = useSocialStore();
    const showcaseStore = useShowcaseStore();

    if (
      !showcaseStore.product ||
      showcaseStore.product.slug !== to.params.slug
    ) {
      const { data } = await showcaseService.getProductBySlug(
        to.params.slug as string,
      );
      showcaseStore.product = data;
    }

    //If is room owner, advance as usual
    if (
      showcaseStore.product.product?.ownerId == socialStore._socialUser?._id
    ) {
      //If room does not have editor & query.editor params is true, remove query params
      if (!showcaseStore.product.product?.haveEditor && to.query.editor) {
        next({ path: to.path, query: {} });
        return;
      }

      next();
      return;
    }

    //If room is not public and is not room owner
    /*if (!room.public) {
      toast.error({
        message:
          'The room you were trying to enter is private, please try another room.',
        duration: 5000,
      });

      return;
    } ALL ROOMS ARE PUBLIC*/

    //Its not owner, but has set query.editor = true manually, remove query params
    if (to.query.editor) {
      next({ path: to.path, query: {} });
      return;
    }

    next();
  },
  mustBeLoggedOut: (_, __, next) => {
    const authStore = useAuthStore();

    if (!authStore.$state.user?.id) {
      next();
      return;
    }

    next({ path: '/' });
  },
  canEnterRoomUPM: (to, _, next) => {
    const authStore = useAuthStore();
    /*const { data } = await showcaseService.getProductBySlug(
      to.params.slug as string,
    );*/
    if (!(to.params.slug as string).toLowerCase().includes('upm')) {
      next();
      return;
    }

    if (authStore.user?.email.includes('@upm.es')) {
      next();
      return;
    }

    authStore.canDoModalProps.showCanDoModal = true;
    authStore.canDoModalProps.canDoTitle = 'Private Room';
    authStore.canDoModalProps.canDoMessage =
      "You don't have access to this Room. Continue enjoying all the content from Legendaryum.";
    authStore.redirectToHomeOnCloseCanDoModal =
      to.name == 'RoomView' && _.matched.length === 0;

    return;
  },
  isCurrentUser: (to, _, next) => {
    const socialStore = useSocialStore();

    if (to.params.id === socialStore._socialUser?._id) {
      next({ name: 'ProfileView' });
    }

    next();
  },
  ssoUpm: async (to, _, next) => {
    const authStore = useAuthStore();
    if (
      to.query.roomname &&
      to.query.roomname.includes('Upm') &&
      to.query.code
    ) {
      try {
        await authStore.login('', '', 'upm', to.query.code as string);
        next({ path: '/' });
      } catch (error) {
        toast.error({ message: error as string });
        next({ path: '/' });
      }
    }
    next({ path: '/' });
  },
  // La autenticación con google también genera un "code", por lo que al autenticarse con google se cierra
  // esa ventana emergente, pero antes trata de volver a "/home" por lo que llega a este guard, si este guard se lo enchufa
  // en HomeView --> está en HomeView para corregir un bug con microsoft, se tendrá que corregir y replicar luego.
  ssoMicrosoft: async (to, _, next) => {
    const authStore = useAuthStore();
    if (to.query.code) {
      try {
        await authStore.login('', '', 'microsoft', to.query.code as string);
        next({ path: '/' });
      } catch (error) {
        toast.error({ message: error as string });
        next({ path: '/' });
      }
    }
    next();
  },

  isBlockedUserProfile: async (to, from, next) => {
    const authStore = useAuthStore();
    const socialStore = useSocialStore();
    const owner = to.params.id as string;

    // Check if the user tries to enter through the URL
    if (to.name == 'UserView' && from.matched.length === 0) {
      authStore.redirectToHomeOnCloseCanDoModal = true;
    }

    try {
      // check if this user has blocked me
      const hasBlockedMe = await socialStore.hasBlockedMe(owner);
      if (hasBlockedMe) {
        authStore.canDoModalProps.showCanDoModal = true;
        authStore.canDoModalProps.canDoTitle = 'User unreachable';
        authStore.canDoModalProps.canDoMessage =
          "You don't have access to this user profile because you are blocked.";
        return;
      }

      // check if I blocked this user
      const ownerIsBlocked = Boolean(
        socialStore._socialUser?.blockedUsers?.find(
          (blocked) => blocked._id === owner,
        ),
      );

      if (ownerIsBlocked) {
        authStore.canDoModalProps.showCanDoModal = true;
        authStore.canDoModalProps.canDoTitle = 'User unreachable';
        authStore.canDoModalProps.canDoMessage =
          "You don't have access to this user profile because you have blocked this user.";
        return;
      }
      next();
      return;
    } catch (error) {
      const { message } = handleAxiosError(error);
      toast.error({
        title: 'An error occurred.',
        message,
      });
    }
  },

  isBlockedUserRoom: async (to, from, next) => {
    const authStore = useAuthStore();
    const socialStore = useSocialStore();
    const showcaseStore = useShowcaseStore();

    // Check if the user tries to enter through the URL
    if (to.name == 'RoomView' && from.matched.length === 0) {
      authStore.redirectToHomeOnCloseCanDoModal = true;
    }

    try {
      if (
        !showcaseStore.product ||
        showcaseStore.product.slug !== to.params.slug
      ) {
        const { data } = await showcaseService.getProductBySlug(
          to.params.slug as string,
        );
        showcaseStore.product = data;
      }
      // check if it is a public room
      if (showcaseStore.product.public) {
        next();
        return;
      }

      // check if this user has blocked me
      const owner = showcaseStore.product.product?.ownerId ?? '';
      const hasBlockedMe = await socialStore.hasBlockedMe(owner);

      // check if this user has blocked me
      if (hasBlockedMe) {
        authStore.canDoModalProps.showCanDoModal = true;
        authStore.canDoModalProps.canDoTitle = 'Room unreachable';
        authStore.canDoModalProps.canDoMessage =
          'You cannot enter because you are blocked.';
        return;
      }

      // check if I blocked this user
      const ownerIsBlocked = Boolean(
        socialStore._socialUser?.blockedUsers?.find(
          (blocked) => blocked._id === owner,
        ),
      );
      if (ownerIsBlocked) {
        authStore.canDoModalProps.showCanDoModal = true;
        authStore.canDoModalProps.canDoTitle = 'Room unreachable';
        authStore.canDoModalProps.canDoMessage =
          'You cannot enter because you blocked this user.';
        return;
      }

      next();
      return;
    } catch (error) {
      handleAxiosError(error, true);
    }
  },
  roomHasProduct: async (to, from, next) => {
    const authStore = useAuthStore();
    const showcaseStore = useShowcaseStore();

    try {
      if (
        !showcaseStore.product ||
        showcaseStore.product.slug !== to.params.slug
      ) {
        const { data } = await showcaseService.getProductBySlug(
          to.params.slug as string,
        );
        showcaseStore.product = data;
      }
    } catch (error) {
      handleAxiosError(error, true);
      next({ path: '/' });
      return;
    }
    const hasProduct = Boolean(showcaseStore.product.product);

    // Check if the user tries to enter through the URL
    if (to.name == 'RoomView' && from.matched.length === 0) {
      authStore.redirectToHomeOnCloseCanDoModal = true;
    }

    if (!hasProduct) {
      authStore.canDoModalProps.showCanDoModal = true;
      authStore.canDoModalProps.canDoTitle = 'Room unreachable';
      authStore.canDoModalProps.canDoMessage =
        'You cannot enter, try again later.';
      return;
    }
    next();
    return;
  },

  isWalletRequired: async (to, from, next) => {
    const authStore = useAuthStore();
    if (
      !to.query.rankingId ||
      (to.query.rewardType !== 'Crypto' && to.query.rewardType !== 'Nft')
    ) {
      next();
      return;
    }

    try {
      await blockchainService.checkWalletAddress(
        authStore.walletAddress.wallet,
      );
      next();
      return;
    } catch (error) {
      handleAxiosError(error);
    }

    next({ path: from.path });
    authStore.showLoginWalletModal = true;
    return;
  },

  isProdEnvironment: (_, __, next) => {
    if (
      import.meta.env.VITE_ENV === 'dev' ||
      import.meta.env.VITE_ENV === 'staging'
    ) {
      next();
      return;
    } else {
      next({ path: '/' });
      return;
    }
  },
};
