import { usePaymentsStore } from '@/stores/payments';
import { formatTimestamp } from '@/utils/format';
import { computed } from 'vue';

export interface FreeTierSubscription {
  id: number;
  name: string;
  images: string[];
  metadata: {
    coins: 0;
  };
  default_price: {
    currency: string;
    unit_amount: 0;
  };
}

const useSubscriptions = () => {
  const paymentsStore = usePaymentsStore();

  const freeTierSubscription: FreeTierSubscription = {
    id: 0,
    name: 'Free Subscription',
    images: ['/images/Badge1.webp'],
    metadata: {
      coins: 0,
    },
    default_price: {
      currency: '',
      unit_amount: 0,
    },
  };

  const currentSubscription = computed(() => {
    if (!paymentsStore.currentSubscriptionPriceId) return freeTierSubscription;
    return paymentsStore.subscriptions.find(
      (s) => s.default_price.id == paymentsStore.currentSubscriptionPriceId,
    );
  });

  const currentPaidSubscription = computed(() => {
    if (!paymentsStore.paidSubscriptionPriceId) return freeTierSubscription;
    return paymentsStore.subscriptions.find(
      (s) => s.default_price.id == paymentsStore.paidSubscriptionPriceId,
    );
  });

  return {
    //data
    currentSubscription,
    currentPaidSubscription,
    currentSubscriptionCancelAtPeriodEnd: computed(
      () => paymentsStore.currentSubscriptionCancelAtPeriodEnd,
    ),
    currentSubscriptionCancelPeriodEndDate: computed(() => {
      if (!paymentsStore.currentSubscription?.cancel_at) return '';
      return formatTimestamp(
        paymentsStore.currentSubscription.cancel_at * 1000,
      );
    }),
    currentSubscriptionPeriodEndDate: computed(() => {
      if (!paymentsStore.currentSubscription?.current_period_end) return '';
      return formatTimestamp(
        paymentsStore.currentSubscription.current_period_end * 1000,
      );
    }),
    currentSubscriptionPriceId: computed(
      () => paymentsStore.currentSubscriptionPriceId,
    ),
    paidSubscriptionPriceId: computed(
      () => paymentsStore.paidSubscriptionPriceId,
    ),
    freeTierSubscription,
    subscriptions: computed(() => paymentsStore.subscriptions),
  };
};

export default useSubscriptions;
