import { useSocialStore } from './social';
import type {
  DMChat,
  DM,
  ChatMessageRecord,
  RoomChatMessageRecord,
  MinimizedSocialUser,
} from '@/services/rapid-messaging.service.types';
import { defineStore } from 'pinia';
import { QueryClient } from '@tanstack/vue-query';
import {
  socialService,
  rapidMessagingService,
  type MinifiedSocialUser,
  type SocialUser,
} from '@/services';
import router from '@/router';
import { connectRapidMessagingSocket } from '@/services/rapid-messaging.socket';
import { toast } from '@/utils/toast';
import { truncateText } from '@/utils/format';
import { handleAxiosError } from '@/utils/error';
import { getFullName, getUiAvatar } from '@/utils';

const queryClient = new QueryClient();

interface DirectMessageState {
  chats: ChatMessageRecord[];
  users: MinifiedSocialUser[];
  chat: ChatMessageRecord | undefined;
  roomChat: RoomChatMessageRecord[] | undefined;
  roomUsers: MinimizedSocialUser[];
  lastModelRefId: string | null;
  lastChatId: string | null;
  currentUser: MinimizedSocialUser | undefined;
}

export const useDirectMessageStore = defineStore('directMessage', {
  state: (): DirectMessageState => ({
    chats: [],
    users: [],
    chat: undefined,
    roomChat: undefined,
    roomUsers: [],
    lastModelRefId: null,
    lastChatId: null,
    currentUser: undefined,
  }),
  getters: {
    currentChatRecordId: (state) => state.chat?._id,
    currentChatId: (state) => state.chat?.chatId._id,
    targetUserId: (state) => {
      const socialStore = useSocialStore();
      if (!state.chat) return '';
      const users = state.chat.chatId.users;
      return (
        users.find((userId) => userId !== socialStore._socialUser?._id) ?? ''
      );
    },
    targetUser: (state) => {
      const socialStore = useSocialStore();

      if (!socialStore._socialUser) return;

      const userId = state.chat?.chatId.users.find(
        (id) => id != socialStore._socialUser?._id,
      );

      return state.users.find((user) => user._id === userId);
    },
  },
  actions: {
    async getChats() {
      const socialStore = useSocialStore();
      try {
        const { data } = await rapidMessagingService.getDirectMessages();

        this.chats = data;

        if (!socialStore._socialUser?._id) return;

        const userIds = this.chats.reduce<string[]>((acc, val) => {
          const userId = val.chatId.users.find(
            (id) => id != socialStore._socialUser?._id,
          );
          if (userId) {
            acc.push(userId);
          }
          return acc;
        }, []);

        const { data: users } = await queryClient.fetchQuery({
          queryKey: ['direct-messages-users'],
          queryFn: () => {
            return socialService.getUsersById(userIds);
          },
        });

        this.users = users;
      } catch (error) {
        console.error(error);
      }
    },
    async getChat(id: string) {
      try {
        const { data } = await queryClient.fetchQuery({
          queryKey: ['direct-message', id],
          queryFn: () => {
            return rapidMessagingService.getMessagesByChatId(id);
          },
        });

        this.chat = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRoomChat(id: string) {
      try {
        const { data } = await queryClient.fetchQuery({
          queryKey: ['room-message', id],
          queryFn: () => {
            return rapidMessagingService.getMessagesByRoomRefId(id);
          },
        });

        this.roomChat = data;
      } catch (error) {
        console.error(error);
      }
    },
    getChatUser(chat: ChatMessageRecord) {
      const socialStore = useSocialStore();

      if (!socialStore._socialUser) return;

      const userId = chat.chatId.users.find(
        (id) => id != socialStore._socialUser?._id,
      );

      return this.users.find((user) => user._id === userId);
    },
    async markChatAsSeen(recordId: string) {
      try {
        await rapidMessagingService.markRecordAsSeen(recordId);

        queryClient.removeQueries({
          queryKey: ['direct-message', recordId],
        });

        const chatIndex = this.chats.findIndex((chat) => chat._id === recordId);
        if (chatIndex !== -1) this.chats[chatIndex].seen = true;

        if (this.chat && this.chat._id === recordId) this.chat.seen = true;
      } catch (error) {
        console.error(error);
      }
    },
    async markChatsAsSeen() {
      try {
        await rapidMessagingService.markRecordsAsSeen();

        this.chats.forEach((chat) => (chat.seen = true));

        if (this.chat) this.chat.seen = true;
      } catch (error) {
        console.error(error);
      }
    },
    async addMessage(message: DM, chat: DMChat) {
      queryClient.removeQueries({
        queryKey: ['direct-message', chat._id],
      });

      let chatSeen = false;
      const chatIndex = this.chats.findIndex(
        (c) => c.chatId._id === message.chatId,
      );
      if (this.chat && this.chat.chatId._id === chat._id)
        this.chat.messages.push(message);
      if (chatIndex !== -1) {
        this.chats[chatIndex].messages.unshift(message);
        const chatToMove = this.chats.splice(chatIndex, 1)[0];
        this.chats.unshift(chatToMove);
        if (router.currentRoute.value.query.id === chat._id) {
          chatSeen = true;

          await this.markChatAsSeen(chatToMove._id);
        }
        chatToMove.seen = chatSeen;
      } else {
        await this.getChats();
      }
    },

    addRoomMessage(
      _id: string,
      content: string,
      chatId: string,
      minimizedSocialUser: MinimizedSocialUser,
      createdAt: Date,
    ) {
      queryClient.removeQueries({
        queryKey: ['room-message', chatId],
      });

      this.roomChat?.push({
        _id,
        content,
        chatId,
        minimizedSocialUser,
        createdAt,
      });
    },
    showNotification(message: DM, userInfo: SocialUser) {
      if (router.currentRoute.value.query.id === message.chatId) return;
      toast.info({
        title: `New message from ${getFullName(userInfo)}`,
        message: truncateText(message.content, 6),
        duration: 3000,
        props: {
          image:
            userInfo.userImage ??
            userInfo.avatarScreenshot ??
            userInfo.avatar ??
            getUiAvatar(userInfo),
          actions: [
            {
              text: 'Reply',
              onClick: async () => {
                await router.push({
                  name: 'DirectMessagesView',
                  query: { id: message.chatId },
                });
              },
            },
          ],
        },
      });
    },
    async deleteRecord(chatId: string) {
      await rapidMessagingService.clearRecord(chatId);

      await router.push({
        name: 'DirectMessagesView',
      });

      const chatIndex = this.chats.findIndex(
        (chat) => chat.chatId._id === chatId,
      );
      if (chatIndex !== -1) this.chats.splice(chatIndex, 1);

      this.chat = undefined;
    },
    async newChatRedirect(
      user: SocialUser | MinifiedSocialUser | MinimizedSocialUser,
    ) {
      try {
        const response = await rapidMessagingService.existChat(user._id);

        if (!response.data._id) {
          throw new Error('No chatId');
        }

        await router.push({
          name: 'DirectMessagesView',
          query: {
            id: response.data._id,
          },
        });
      } catch (error) {
        await router.push({
          name: 'DirectMessagesView',
          query: {
            create: user._id,
          },
        });
      }
    },
    async initChat(recieverId: string, message: string, noRedirect?: boolean) {
      const socialStore = useSocialStore();
      const socket = connectRapidMessagingSocket();

      if (!socialStore._socialUser) return;

      try {
        const response = await rapidMessagingService.createChat(recieverId);
        const newChat = response.data;

        socket.emit('sendDM', {
          chatId: newChat._id,
          content: message,
          receiverId: recieverId,
          userId: socialStore._socialUser._id,
        });

        //delay to wait for socket send.
        setTimeout(async () => {
          await this.getChats();

          if (!noRedirect) {
            await router.push({
              name: 'DirectMessagesView',
              query: { id: newChat._id },
            });
          }
        }, 500);
        return newChat._id;
      } catch (error) {
        handleAxiosError(error, true);
      }
    },
    sendBulkMessages(ids: string[], message: string) {
      const socialStore = useSocialStore();
      const socket = connectRapidMessagingSocket();
      if (!socialStore._socialUser) return;

      socket.emit('sendDMInBatch', {
        content: message,
        receiverIds: ids,
        userId: socialStore._socialUser._id,
      });
    },
  },
});
