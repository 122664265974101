import axios from 'axios';

const blockchainBack = axios.create({
  baseURL: import.meta.env.VITE_LEGEND_BLOCKCHAIN,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const blockchainService = {
  async checkWalletAddress(wallet_address: string) {
    return await blockchainBack.put('/sequence-user', {
      wallet_address,
    });
  },
};
