import {
  type MinimizedSocialUser,
  type MinifiedSocialUser,
  type SocialUser,
} from '@/services';

export const getUiAvatar = <T extends MinifiedSocialUser | MinimizedSocialUser>(
  userInfo: T,
) => {
  return `https://ui-avatars.com/api/?name=${getFullName(userInfo, '+')}`;
};
// firstname and lastname can be optional, last option is username that is
// always present (we generate it)
// --separator -> only if firstname and lastname are present
export const getFullName = (
  userInfo: SocialUser | MinifiedSocialUser | MinimizedSocialUser,
  separator = ' ',
) => {
  if (userInfo.firstName && userInfo.lastName) {
    return `${userInfo.firstName}${separator}${userInfo.lastName}`;
  }
  return userInfo.firstName ?? userInfo.username;
};
