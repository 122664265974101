import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import { guards } from './route-guards';
import { checkoutReturnHandler } from '@/utils/checkout';
import { useHead } from '@unhead/vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue'),
    props: (route) => ({
      slug: (route.params.slug = 'home'),
    }),
    // Se elimina guards.ssoMicrosoft, dado que genera conflicto con la auth con google.
    beforeEnter: [guards.checkIfLoggedIn],
  },
  {
    path: '/collection/',
    beforeEnter: [guards.checkIfLoggedIn],
    children: [
      {
        path: ':slug',
        name: 'CollectionView',
        component: () => import('@/views/CollectionView.vue'),
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: ':collectionSlug/category/:categorySlug',
        name: 'CategoryView',
        component: () => import('@/views/CategoryView.vue'),
        props: (route) => ({
          collectionSlug: route.params.collectionSlug,
          categorySlug: route.params.categorySlug,
        }),
      },
    ],
  },
  {
    path: '/visual-category/:slug',
    name: 'VisualCategoryView',
    component: () => import('@/views/VisualCategoryView.vue'),
    props: (route) => ({ slug: route.params.slug }),
    beforeEnter: [guards.checkIfLoggedIn],
  },
  {
    path: '/missions',
    name: 'MissionsView',
    component: () => import('@/views/MissionsView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/rankings',
    name: 'RankingsView',
    component: () => import('@/views/RankingsView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/wallet',
    name: 'WalletView',
    redirect: '/wallet/tokens',
    component: () => import('@/views/wallet/WalletView.vue'),
    children: [
      {
        path: 'tokens',
        name: 'WalletToken',
        component: () => import('@/views/wallet/WalletToken.vue'),
      },
      {
        path: 'nfts',
        name: 'WalletNFT',
        component: () => import('@/views/wallet/WalletNFT.vue'),
      },
      {
        path: 'activity',
        name: 'WalletActivity',
        component: () => import('@/views/wallet/WalletActivity.vue'),
      },
      {
        path: 'activity/:txn',
        name: 'WalletActivityByTxn',
        component: () => import('@/views/wallet/WalletActivityByTxn.vue'),
      },
      {
        path: 'nft',
        name: 'CurrentNFT',
        component: () => import('@/views/wallet/WalletCurrentNFT.vue'),
        props: (route) => ({
          tokenID: route.query.tokenID,
          contractAddress: route.query.contractAddress,
        }),
      },
      {
        path: 'nft/send',
        name: 'SendNFT',
        component: () => import('@/views/wallet/WalletSendNFT.vue'),
        props: (route) => ({
          chainID: route.query.chainID,
          tokenID: route.query.tokenID,
          contractAddress: route.query.contractAddress,
          contractType: route.query.contractType,
        }),
      },
      {
        path: 'token/:symbol',
        name: 'CurrentToken',
        component: () => import('@/views/wallet/WalletCurrentToken.vue'),
        props: (route) => ({
          tokenID: route.query.tokenID,
          contractAddress: route.query.contractAddress,
          contractType: route.query.contractType,
        }),
      },
      {
        path: 'token/send',
        name: 'SendToken',
        component: () => import('@/views/wallet/WalletSendToken.vue'),
        props: (route) => ({
          chainID: route.query.chainID,
          tokenID: route.query.tokenID,
          contractAddress: route.query.contractAddress,
          contractType: route.query.contractType,
        }),
      },
      {
        path: 'successful',
        name: 'SentSuccessful',
        component: () => import('@/views/wallet/WalletSentSuccessful.vue'),
        props: (route) => ({
          network: route.query.network,
          txHash: route.query.txHash,
          contractType: route.query.contractType,
        }),
      },
    ],
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/room/:slug/',
    name: 'RoomView',
    component: () => import('@/views/RoomView.vue'),
    props: (route) => ({ slug: route.params.slug }),
    beforeEnter: [
      guards.mustBeLoggedIn,
      guards.canEnterRoomUPM,
      guards.roomHasProduct,
      guards.isRoomOwner,
      guards.isBlockedUserRoom,
    ],
  },
  {
    name: 'ExperienceView',
    path: '/experience/:slug',
    component: () => import('@/views/ExperienceView.vue'),
    props: (route) => ({
      slug: route.params.slug,
      rankingId: route.query.rankingId,
      rewardType: route.query.rewardType,
    }),
    beforeEnter: [
      guards.mustBeLoggedIn,
      guards.canEnterRoomUPM,
      guards.isWalletRequired,
    ],
  },
  {
    name: 'EventView',
    path: '/event/:slug',
    component: () => import('@/views/EventView.vue'),
    props: (route) => ({ slug: route.params.slug }),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/user/rooms',
    name: 'MyRoomsView',
    component: () => import('@/views/MyRoomsView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  // En el commit 0ddad60c1bd0a0226ff92359d98aef78f6e0e268 existía aún lo relacionado a notifications.
  // {
  //   name: 'Notifications',
  //   path: '/notifications/',
  //   component: () => import('@/views/NotificationsView.vue'),
  //   props: (route) => ({ id: route.query.id }),
  //   beforeEnter: [guards.mustBeLoggedIn],
  // },
  {
    name: 'DirectMessagesView',
    path: '/direct-messages',
    component: () => import('@/views/DirectMessagesView.vue'),
    props: (route) => ({
      id: route.query.id,
      newChatUserId: route.query.create,
    }),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/search',
    redirect: '/search/legenders',
    children: [
      // {
      //   path: 'experiences',
      //   component: () => import('@/views/SearchView.vue'),
      //   name: 'SearchView',
      // },
      {
        path: 'legenders',
        component: () => import('@/views/SearchUsersView.vue'),
        name: 'SearchUserView',
      },
    ],
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    name: 'UserView',
    path: '/user/:id',
    component: () => import('@/views/UserView.vue'),
    props: (route) => ({ userId: route.params.id }),
    beforeEnter: [
      guards.mustBeLoggedIn,
      guards.isCurrentUser,
      guards.isBlockedUserProfile,
    ],
  },
  {
    name: 'ProfileView',
    path: '/profile',
    component: () => import('@/views/ProfileView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    path: '/favorites',
    name: 'FavoritesView',
    component: () => import('@/views/FavoritesView.vue'),
    props: true,
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    name: 'BalanceView',
    path: '/balance',
    component: () => import('@/views/BalanceView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  {
    name: 'SubscriptionsView',
    path: '/subscriptions',
    component: () => import('@/views/SubscriptionsView.vue'),
    beforeEnter: [guards.mustBeLoggedIn],
  },
  // Esta ruta existe unicamente por UPM.(No borrar)
  {
    path: '/labUnity',
    component: () => import('@/views/HomeView.vue'),
    props: true,
    beforeEnter: [guards.ssoUpm],
  },
  {
    name: 'AboutView',
    path: '/about',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    name: 'ContactView',
    path: '/contact',
    component: () => import('@/views/ContactView.vue'),
  },
  {
    name: 'ReportView',
    path: '/report',
    component: () => import('@/views/ReportView.vue'),
    beforeEnter: [guards.checkIfLoggedIn],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/404View.vue'),
    beforeEnter: [guards.checkIfLoggedIn],
  },
  {
    path: '/sequence',
    name: 'SequenceView',
    component: () => import('@/views/404View.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.path != from.path) {
      return { left: 0, top: 0 };
    }

    return { left: savedPosition?.left, top: savedPosition?.top };
  },
  routes,
});

// Route handler for redirected checkout return
router.afterEach(async (to) => {
  const canonicalUrl = (to.meta.canonical as string) || window.location.href;
  useHead({
    link: [
      {
        rel: 'canonical',
        href: canonicalUrl,
      },
    ],
  });
  await checkoutReturnHandler(to);
});

const knowErrors = [
  'Importing a module script failed',
  'Failed to fetch dynamically imported module',
];
router.onError((error, to) => {
  if (knowErrors.some((msg) => (error as Error).message.includes(msg))) {
    // silently handle this error
    // https://github.com/microsoft/TypeScript/issues/48949#issuecomment-1117142997
    (window as Window).location = to.fullPath;
  }
});
// https://vite.dev/guide/build#load-error-handling
// El handler "router.onError" rutea a la última ruta visitada/intento de visita en caso de error.
// window.addEventListener('vite:preloadError', (_event) => {
//   window.location.reload();
// });

export default router;
