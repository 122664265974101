<script setup lang="ts">
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import GenericModal from '../GenericModal.vue';
import { useAuthStore } from '@/stores';
import { useWalletStore } from '@/stores/wallet';

const authStore = useAuthStore();

function handleOpenWallet() {
  authStore.showLoginWalletModal = false;
  useWalletStore().showWalletModal = true;
}
</script>

<template>
  <GenericModal
    v-model="authStore.showLoginWalletModal"
    size="sm"
    class=""
    container-class="pt-2"
    background-class="bg-black/10"
    @close="authStore.showLoginWalletModal = false"
  >
    <div class="flex flex-col items-center justify-center gap-5 p-2 pb-1">
      <p>You must log in with your wallet to participate in this ranking</p>

      <ButtonComponent width="xs" @click="handleOpenWallet"
        >Open Wallet <IconifyIcon icon="ion:wallet-outline" class="ml-2 size-6"
      /></ButtonComponent>
    </div>
  </GenericModal>
</template>
