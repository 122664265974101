import {
  usePaymentsStore,
  useRoomInventoryStore,
  useShowcaseStore,
} from '@/stores';
import type {
  MinifiedSocialUser,
  SocialMedias,
  SocialUser,
} from '@/services/social.service.types.ts';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth.ts';
import { toast } from '@/utils/toast';
import { socialService } from '@/services/social.service.ts';
import { connectRapidMessagingSocket } from '@/services/rapid-messaging.socket.ts';
import { useDirectMessageStore } from './directMessage.ts';
import { connectCoinsSocket } from '@/services/coins.socket.ts';
import { queryClient } from '@/plugins/vue-query.ts';
import { handleAxiosError } from '@/utils/error.ts';
import { getFullName } from '@/utils';
import { type MinimizedSocialUser } from '@/services/rapid-messaging.service.types.ts';

export interface Social {
  _socialUser?: SocialUser;
  isLoadingUser: boolean;
}

export const useSocialStore = defineStore('social', {
  state: (): Social => ({
    _socialUser: undefined,
    isLoadingUser: true,
  }),
  actions: {
    async initGlobalStores() {
      const paymentsStore = usePaymentsStore();

      await Promise.all([
        paymentsStore.getProducts(),
        paymentsStore.getCoins(),
      ]);
    },
    initGlobalSockets() {
      connectRapidMessagingSocket(this._socialUser?._id);
      connectCoinsSocket(this._socialUser?._id);
    },
    async getMyUser() {
      if (this._socialUser) {
        this.isLoadingUser = false;
        return;
      }
      try {
        const showcaseStore = useShowcaseStore();
        const roomInventoryStore = useRoomInventoryStore();
        const id = useAuthStore().user?.id;
        if (!id) {
          toast.error({ message: 'Error getting user data' });
          return;
        }
        const res = await socialService.getMyUser();
        this._socialUser = res.data;
        this.initGlobalSockets();
        // the main resource is "_socialUser". These resources can be loaded in the "background"
        void this.initGlobalStores();
        void showcaseStore.getMyRooms();
        void roomInventoryStore.getMyTemplates();
      } catch (error) {
        handleAxiosError(error);
      } finally {
        // semi-global loader, it waits for all task in try (even if they fail or are not awaited)
        this.isLoadingUser = false;
      }
    },
    async refreshMyUser() {
      try {
        const id = useAuthStore().user?.id;
        if (!id) {
          toast.error({ message: 'Error getting user data' });
          return;
        }
        const res = await socialService.getMyUser();
        this._socialUser = res.data;
      } catch (err) {
        console.error(err);
        toast.error({ message: 'Error getting user data' });
      }
    },
    clearMyUser() {
      this._socialUser = undefined;
    },
    checkMyUser() {
      if (!this._socialUser) {
        throw new Error(`You don't have a user`);
      }
    },
    async updateSocialUser(newData: Partial<SocialUser>) {
      try {
        this.checkMyUser();
        await socialService.updateSocialUser(newData);
      } catch (error) {
        toast.error({ message: 'User update failed. Please retry.' });
      }
    },
    async addPreferences(preferencesIds: string[]) {
      try {
        this.checkMyUser();
        await socialService.addPreferences(preferencesIds);
      } catch (error) {
        toast.error({ message: 'User update failed. Please retry.' });
      }
    },
    async removePreferences(preferencesIds: string[]) {
      try {
        this.checkMyUser();
        await socialService.removePreferences(preferencesIds);
      } catch (error) {
        toast.error({ message: 'User update failed. Please retry.' });
      }
    },
    async uploadSocialMedias(socialMedias: SocialMedias) {
      try {
        this.checkMyUser();
        await socialService.uploadSocialMedias(socialMedias);
      } catch (error) {
        toast.error({ message: 'User update failed. Please retry.' });
      }
    },
    async uploadUserImage(userImg: string) {
      try {
        this.checkMyUser();
        await socialService.uploadUserImage(userImg);
      } catch (error) {
        toast.error({ message: 'User update failed. Please retry.' });
      }
    },
    async setAvatar(avatarId: string) {
      try {
        this.checkMyUser();
        if (!this._socialUser?.RPMUserId)
          throw new Error(`You don't have an avatar`);
        const response = await socialService.saveAvatar(avatarId);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- because unity can't have started
        if (window.unityController) {
          window.unityController.changeAvatar(response.data.glbUrl);
        }
        this._socialUser.RPMAvatarId = response.data.RPMAvatarId;
        this._socialUser.glbUrl = response.data.glbUrl;
        toast.success({ message: 'Avatar updated successfully' });
      } catch (error) {
        toast.error({ message: 'Avatar creation failed. Try again.' });
      }
    },
    async createReadyPlayerMeUserId() {
      try {
        this.checkMyUser();
        if (!this._socialUser) return;
        const response = await socialService.postAvatarUserId();
        this._socialUser.RPMUserId = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async follow(user: SocialUser | MinifiedSocialUser | MinimizedSocialUser) {
      const directMessageStore = useDirectMessageStore();
      try {
        await socialService.follow(user._id);
        await queryClient.invalidateQueries({
          queryKey: ['social-user', user._id],
        });
        await queryClient.invalidateQueries({
          queryKey: ['user-followers', user._id],
        });
        toast.success({
          title: `🎉 You are now following ${getFullName(user)}.`,
          message: "Don't forget to say hi!",
          props: {
            actions: [
              {
                text: 'Send message',
                async onClick() {
                  await directMessageStore.newChatRedirect(user);
                },
              },
            ],
          },
        });

        await this.refreshMyUser();
      } catch (error) {
        toast.error({
          title: `There was an error with the request.`,
          message: 'Please try again later.',
        });
      }
    },
    async unfollow(
      user: SocialUser | MinifiedSocialUser | MinimizedSocialUser,
    ) {
      try {
        await socialService.unfollow(user._id);
        await queryClient.invalidateQueries({
          queryKey: ['user-followers', user._id],
        });
        toast.info({
          title: `You have stopped following  ${getFullName(user)}.`,
          message: "I'm sure he's sad to see you go 😥",
        });

        await this.refreshMyUser();
      } catch (error) {
        toast.error({
          title: `There was an error with the request.`,
          message: 'Please try again later.',
        });
      }
    },
    async block(user: SocialUser | MinifiedSocialUser | MinimizedSocialUser) {
      try {
        await socialService.blockUser(user._id);

        toast.info({
          title: ` ${getFullName(user)} has been blocked.`,
          message: "This user won't be able to interact with you anymore.",
          props: {
            icon: {
              name: 'lets-icons:lock',
              color: 'secondary',
            },
          },
        });

        await this.refreshMyUser();
      } catch (error) {
        toast.error({
          title: `There was an error with the request.`,
          message: 'Please try again later.',
        });
      }
    },
    async unblock(user: SocialUser | MinifiedSocialUser | MinimizedSocialUser) {
      try {
        await socialService.unblockUser(user._id);

        toast.info({
          title: ` ${getFullName(user)} has been unblocked.`,
          message: 'You can interact with this user again!',
          props: {
            icon: {
              name: 'lets-icons:unlock',
              color: 'secondary',
            },
          },
        });

        await this.refreshMyUser();
      } catch (error) {
        toast.error({
          title: `There was an error with the request.`,
          message: 'Please try again later.',
        });
      }
    },

    async hasBlockedMe(_userId: string) {
      try {
        const response = await socialService.hasBlockedMe(_userId);
        return response.data.hasBlockedMe;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
});

export default useSocialStore;
